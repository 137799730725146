import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>Kreatin9 - Best Brand Videos</title>
                <meta name="description" content="Kreatin9 - Video Stories of Impact " />
                <meta name="og:title" property="og:title" content="Kreatin9 - Videos -World of Imagination"></meta>
                <meta name="twitter:card" content="Kreatin9 - Best Brand Videos"></meta>
                <link rel="canonical" href="https://kreatin9.com/"></link>
                <meta property="og:image" content="/src/assets/images/logo.png" />
            </Helmet>
        </div>
    )
}

export default SEO
